import React from "react";
import { GallerySection } from "./styled";
import CrackWalls from '../../../assets/png/Crack_Free_walls.jpg';
import BlueWall from '../../../assets/png/blue-wall.jpg';
import BlueWall2 from '../../../assets/png/wall2.jpeg'

import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const GalleryView = () => {
  const navigate = useNavigate();

  return (
    <GallerySection id='gallery'>
      <h2>Our works</h2>{" "}
      <div className="image-section">
        <img src={CrackWalls} className="gallary-image" alt="gallery-image" />
        <img src={BlueWall} className="gallary-image" alt="gallery-image" />
        <img src={BlueWall2} className="gallary-image" alt="gallery-image" />
      </div>
      <Button
        className="view-more-gallery"
        onClick={() => {
          navigate("/gallery");
        }}
      >
        view more <i className="fas fa-arrow-right"></i>
      </Button>
    </GallerySection>
  );
};

export default GalleryView;

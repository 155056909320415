import {
  Box,
  HStack,
  Heading,
  Image,
  Show,
  SimpleGrid,
  Text,
  VStack,
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import React, { useEffect } from "react";
import { Card } from "react-bootstrap";
import paintJson from "../../../assets/json/paint.json";
import img1 from "../../../assets/service/design.jpg";
import img from "../../../assets/service/house.jpg";
import LottieImport from "../base/LottieImport";

function HousePaining() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <SimpleGrid
        py={5}
        display={{ xl: "flex" }}
        justifyContent={{ xl: "center" }}
        px={{ xl: 5 }}
        alignItems={"center"}
        columns={{ base: 1, md: 1, xl: 2 }}
        gap="20px"
      >
        <motion.div
          initial={{ opacity: 0, scale: 0.5 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.5 }}
          className="content"
        >
          <Box px={2} maxW={{ md: "8rem", xl: "40rem" }}>
            <Card>
              <Box p={5}>
                <Image src={img} alt="Hero image" />
              </Box>
            </Card>
          </Box>
        </motion.div>
        <VStack
          px={2}
          maxW={{ md: "28.125rem", xl: "50%" }}
          align={{ base: "start", md: "center", xl: "start" }}
          textAlign={{ base: "start", md: "center", xl: "start" }}
          spacing={{ base: "1.6rem", md: "1.65rem" }}
        >
          <HStack>
            <Show above='md'>
            <LottieImport style={{ position: "absolute" }} json={paintJson} />
            </Show>
            <Heading color={"#00308C"} position={"relative"} as="h1" size="3xl">
              New House Painting
            </Heading>
          </HStack>
          <Text fontSize={20} position={"relative"} color="muted">
            House painting goes beyond simply applying colors to walls; it
            involves a thoughtful process of selecting the right hues, finishes,
            and techniques to create the desired ambiance. The color scheme sets
            the tone for each room, evoking specific emotions and moods. Neutral
            tones, such as soft greys and earthy beiges, exude tranquility and
            sophistication, while bold and vibrant colors add energy and
            personality. Accent walls can serve as focal points, drawing
            attention to architectural features or creating a striking contrast.
            Additionally, painting techniques like color blocking, faux
            finishes, and patterns can add depth, texture, and intrigue to the
            walls, turning them into captivating works of art.
          </Text>
        </VStack>
      </SimpleGrid>
      <SimpleGrid
        py={5}
        display={{ xl: "flex" }}
        justifyContent={{ xl: "center" }}
        px={{ xl: 5 }}
        alignItems={"center"}
        columns={{ base: 1, md: 1, xl: 2 }}
        gap="20px"
      >
        {/* <LottieImport style={{ position: "absolute" }} json={paintJson} /> */}
        <VStack
          px={2}
          maxW={{ md: "28.125rem", xl: "100%" }}
          align={{ base: "start", md: "center", xl: "start" }}
          textAlign={{ base: "start", md: "center", xl: "start" }}
          spacing={{ base: "1.6rem", md: "1.65rem" }}
        >
          <HStack pt={3}>
            <Heading color={"#00308C"} position={"relative"} as="h1" size="3xl">
              Design Decorations
            </Heading>
          </HStack>

          <Text fontSize={20} position={"relative"}>
            Design decorations are an essential aspect of creating a visually
            appealing and harmonious living space. They add character, style,
            and personality to your home, making it a reflection of your taste
            and preferences. Here are some key elements to consider when it
            comes to design decorations:
            <Text pt={2} fontSize={20} position={"relative"}>
              Furniture Selection: The furniture you choose sets the foundation
              for your overall design scheme. Consider the style, scale, and
              functionality of each piece. Whether you prefer modern,
              traditional, or eclectic styles, select furniture that complements
              the architecture of your home and creates a cohesive and inviting
              atmosphere.
            </Text>
          </Text>
        </VStack>
        <Box px={2} maxW={{ md: "8rem", xl: "40rem" }}>
          <Card>
            <Box p={5}>
              <Image src={img1} alt="Hero image" />
            </Box>
          </Card>
        </Box>
      </SimpleGrid>
    </>
  );
}

export default HousePaining;

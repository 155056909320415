import {
  Box,
  HStack,
  Heading,
  Image,
  SimpleGrid,
  Text,
  VStack,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import paintJson from "../../../assets/json/waves.json";
import img from "../../../assets/service/waterleak.jpg";
import LottieImport from "../base/LottieImport";
import { Card } from "react-bootstrap";

function WaterLeaking() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <SimpleGrid
        py={5}
        display={{ xl: "flex" }}
        justifyContent={{ xl: "center" }}
        px={{ xl: 5 }}
        alignItems={"center"}
        columns={{ base: 1, md: 1, xl: 2 }}
        gap="20px"
      >
        <VStack
          px={3}
          maxW={{ md: "28.125rem", xl: "50%" }}
          align={{ base: "start", md: "center", xl: "start" }}
          textAlign={{ base: "start", md: "center", xl: "start" }}
          spacing={{ base: "1.6rem", md: "1.65rem" }}
        >
          <HStack>
            <LottieImport
              style={{
                paddingLeft: "50px",
                position: "absolute",
              }}
              json={paintJson}
            />
            <Heading color={"#00308C"} position={"relative"} as="h1" size="3xl">
              Water Leaking Treatment
            </Heading>
          </HStack>
          <Text fontSize={20} position={"relative"} lineHeight={10}>
            Water leaking treatment paint is a specialized type of paint
            designed to address and prevent water leaks on surfaces such as
            walls, ceilings, or roofs. This type of paint is formulated with
            waterproofing properties and helps to create a barrier against
            moisture intrusion.
          </Text>
        </VStack>
        <Box maxW={{ md: "8rem", xl: "40rem" }}>
          <Card>
            <Box p={5}>
              <Image src={img} alt="Hero image" />
            </Box>
          </Card>
        </Box>
      </SimpleGrid>
      <Box p={5}>
        <Card>
          <Box fontSize={20} p={5} lineHeight={10}>
            Identify the Source of the Leak: Before applying water leaking
            treatment paint, it is important to identify and address the source
            of the water leak. Inspect the area thoroughly to determine where
            the water is entering from. Common sources include roof leaks,
            plumbing issues, or cracks in the walls. It's crucial to fix the
            underlying issue before applying the paint. Surface Preparation:
            Prepare the surface by cleaning it thoroughly and removing any dirt,
            debris, or loose paint. Repair any visible cracks or damage using
            appropriate sealants or patching compounds. Ensure that the surface
            is dry and free from moisture before applying the water leaking
            treatment paint. Selecting the Right Paint: Choose a high-quality
            water leaking treatment paint that is specifically designed for the
            intended surface and type of leak. There are different types of
            waterproofing paints available, such as elastomeric coatings or
            moisture-resistant sealers. Consult with a professional or
            knowledgeable paint supplier to select the most suitable product for
            your specific needs. Application Technique: Follow the
            manufacturer's instructions for application. Generally, you will
            need to apply the water leaking treatment paint using a brush,
            roller, or sprayer. Apply the paint evenly and make sure to cover
            the entire affected area, including the surrounding edges. Depending
            on the product, multiple coats may be required, so follow the
            recommended drying times between coats. Additional Waterproofing
            Measures: In some cases, simply applying water leaking treatment
            paint may not be sufficient to address the water leak completely.
            Depending on the severity of the issue, additional waterproofing
            measures may be required. This could include installing waterproof
            membranes, sealing cracks or joints with specialized sealants, or
            repairing the roof or plumbing system.
          </Box>
        </Card>
      </Box>
    </>
  );
}

export default WaterLeaking;

import React, { useState } from "react";
// import { FooterSection } from './styled';
import Moira from "./svg/fullLogo.png";
import { Button, Col, Row } from "react-bootstrap";
import "./footer.css";

import Email from "./svg/email-icon.svg";
import Call from "./svg/call-icon.svg";
import EmailIcon from "./svg/email.svg";
import WhiteLogo from "../../assets/png/hellocolored.png";
import RedLogo from "../../assets/png/hellocolors.svg";
import {
  FaInstagram,
  FaLinkedinIn,
  FaTwitter,
  FaYoutube,
  FaWhatsapp,
  FaFacebook,
} from "react-icons/fa";
const Footer = () => {
  const [email, setEmail] = useState("");
  return (
    <div className="footer-section">
      <div className="container">
        <Row className="row-control">
          <Col md={3} sm={12} className="footer-content">
            <img src={RedLogo} className="moira-white-logo" />
            <span className="moira-brief">
              Explore a vibrant world of colors with Hello Color - Unleash your
              creativity and paint your dreams to life!
            </span>
          </Col>

          <div className="contact-section mobile">
            <span className="contact-header mobile-have-question">Have a question?</span>
            <div className="contact-details-section">
              <a href="tel:8550000339" className="contact-details">
                <img src={Call} />
                <div className="contact-content">
                  <span className="contact-name">Office:</span>
                  <span className="contact-number">8550000339</span>
                </div>
              </a>
              <a
                href="https://api.whatsapp.com/send?phone=+918550000339&text=Hey%21%21%21%20%20%20Hello%20Colors."
                target="_blank"
                className="contact-details"
              >
                <FaWhatsapp
                  aria-label="Whatsapp"
                  className="fa-icon-whatsapp"
                />

                <div className="contact-content">
                  <span className="contact-name">WhatsApp:</span>
                  <span className="contact-number">8550000339</span>
                </div>
              </a>
            </div>
          </div>

          <Col md={3} sm={6} className="footer-content web-section">
            <span className="content-header">Social Media</span>
            <span className="content">
              <span
                className="social-media-section"
                onClick={(e) =>
                  window.open(
                    "https://instagram.com/hellocolors.murali?igshid=NGExMmI2YTkyZg=="
                  )
                }
              >
                <FaInstagram aria-label="Instagram" />
                <span>Instagram</span>
              </span>

              <span
                className="social-media-section"
                onClick={(e) =>
                  window.open(
                    "https://www.facebook.com/profile.php?id=100094745409987&mibextid=ZbWKwL"
                  )
                }
              >
                <FaFacebook aria-label="Facebook" />
                <span>Facebook</span>
              </span>
            </span>
          </Col>

          <div className="mobile-section">
            <div className="footer-content relevant-link">
              <span className="content-header">Social Media</span>
              <span className="content link-section">
                <span
                  className="social-media-section"
                  onClick={(e) =>
                    window.open(
                      "https://instagram.com/hellocolors.murali?igshid=NGExMmI2YTkyZg=="
                    )
                  }
                >
                  <FaInstagram aria-label="Instagram" />
                  <span>Instagram</span>
                </span>

                <span
                  className="social-media-section"
                  onClick={(e) =>
                    window.open(
                      "https://www.facebook.com/profile.php?id=100094745409987&mibextid=ZbWKwL"
                    )
                  }
                >
                  <FaFacebook aria-label="Facebook" />
                  <span>Facebook</span>
                </span>
              </span>
            </div>
          </div>
          <Col md={3} sm={12} className="footer-content">
            <span className="content-header">Address</span>
            <span className="content qoruz-brief">
              Hello Colors,
              <br />
              #419,ambedkar nagar,
              <br />
              chikka begur road,Kudlu gate,Singasandra,
              <br />
              Bengaluru,Karnataka,560068
            </span>
          </Col>
          <Col md={3} sm={12} className="footer-content">
            <div className="contact-section web-contact">
              <span className="contact-header">Have a question?</span>
              <div className="contact-details-section">
                <a href="tel:8550000339" className="contact-details">
                  <img src={Call} />
                  <div className="contact-content">
                    <span className="contact-name">Call us on Office:</span>
                    <span className="contact-number">8550000339</span>
                  </div>
                </a>
                <a
                  href="https://api.whatsapp.com/send?phone=+918550000339&text=Hello%21%21%21%20%20%20Moira%20Building%20Systems."
                  target="_blank"
                  className="contact-details"
                >
                  <FaWhatsapp
                    aria-label="Whatsapp"
                    className="fa-icon-whatsapp"
                  />
                  <div className="contact-content">
                    <span className="contact-name">WhatsApp:</span>
                    <span className="contact-number">8550000339</span>
                  </div>
                </a>
              </div>
            </div>
          </Col>
        </Row>

        <span className="copy-right-text">
          © 2023 Hello Colors, All Rights Reserved
        </span>
      </div>
    </div>
  );
};

export default Footer;

import React from "react";
import PropTypes from "prop-types";
import Hamburger from "hamburger-react";
import logo from "../../assets/png/hellocolors.png";
import css from "./Header.module.scss";
import { useNavigate } from "react-router-dom";

const HeaderNavigation = (props) => {
  const { isMenuOpen, setMenuOpen } = props;
  const navigate = useNavigate();

  if (!setMenuOpen) {
    throw new Error("Hamburger menu expects toggle function.");
  }

  const HamburgerOptions = {
    // Toggle state & function
    toggle: () => setMenuOpen(!isMenuOpen),
    toggled: isMenuOpen,

    // Icon optionsdsa
    size: 24,
    duration: 0.25,
    direction: "right",
    label: "Show menu",
  };

  return (
    <div className={css.headerNavigation}>
      <img
        src={logo}
        alt="logo"
        className={css.brandLogo}
        style={{ cursor: "pointer" }}
        onClick={() => navigate("/")}
      />
      <Hamburger {...HamburgerOptions} />
    </div>
  );
};

const { bool, func } = PropTypes;

HeaderNavigation.defaultProps = {
  isMenuOpen: false,
  setMenuOpen: null,
};

HeaderNavigation.propTypes = {
  isMenuOpen: bool.isRequired,
  setMenuOpen: func.isRequired,
};

export default HeaderNavigation;

import styled from "styled-components";

const PhonebookSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: fit-content;
  background: #cbd2d9;
  position: relative;
  margin: 2rem 0;
  border-radius: 10px;
  padding: 2rem;
  gap: 2rem;
  @media screen and (max-width: 680px) {
    flex-direction: column;
    justify-content: flex-start;
    padding: 1rem;
    gap: 3rem;
  }
`;

const PhonebookContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-style: normal;
  @media screen and (max-width: 680px) {
    align-items: center;
    margin-top: 3.25rem;
    margin-left: 0;
  }
  .header-text {
    font-weight: 800;
    font-size: 36px;
    color: #1f2933;
    margin: 0;
    @media screen and (max-width: 680px) {
      font-size: 24px !important;
      line-height: 36px;
    }
  }
  span {
    margin-top: 1rem;
    font-weight: 400;
    font-size: 20px;
    color: #1f2933;
  }
`;

const PhonebookImage = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;

  img {
    width: 100%;
    border-radius: 4px;
    height: 300px;
    @media screen and (max-width: 680px) {
      width: 100%;
    }
  }
`;

export { PhonebookSection, PhonebookContent, PhonebookImage };

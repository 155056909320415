import React, { memo } from "react";
import { PhonebookSection, PhonebookContent, PhonebookImage } from "./styled";

import aboutImage from "../../../assets/png/closeup-house-painting-renovation.jpg";
import aboutImage1 from "../../../assets/png/image.jpg";
const BrandsBook = () => {
  return (
    <div className="container" id='about'>
      <PhonebookSection>
        <PhonebookContent>
          <h2 className="header-text">
            Transform your imagination into vivid masterpieces with our web
            painting website. Explore a world of colors, inspiration, and
            artistic expression, all at your fingertips
          </h2>
          <span>
            Our website is a vibrant canvas where colors breathe life, emotions
            take shape, and imagination knows no boundaries. Step into a virtual
            gallery that exudes inspiration, where each brushstroke tells a
            story and invites you to explore the depths of artistic expression
          </span>
        </PhonebookContent>
        <PhonebookImage>
          <img
            src={aboutImage1}
            className="web-phonebook-image"
            alt="Get discovered by the top brands in India for brand campaign"
          />
        </PhonebookImage>
      </PhonebookSection>
    </div>
  );
};

export default BrandsBook;

import React, { useState } from "react";
import { Container } from "rsuite";
import Footer from "../Footer";
import Header from "../header/Header";
import WhatsApp from "../WhatsApp/WhatsApp";
import BackToTop from "../backToTop/BackToTop";

const DefaultLayout = ({ component: Component, hidePayment }) => {
  // Menu state
  const [isMenuOpen, setMenuOpen] = useState(false);

  return (
    <div className="hello-color-page-container">
      <Header isMenuOpen={isMenuOpen} setMenuOpen={setMenuOpen} />
      <WhatsApp />
      <BackToTop />
      <Component />
      <Footer />
    </div>
  );
};

export default DefaultLayout;

import {
  Box,
  HStack,
  Heading,
  Image,
  SimpleGrid,
  Text,
  VStack,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import paintJson from "../../../assets/json/blast.json";
import img from "../../../assets/service/grillpaint.jpg";
import LottieImport from "../base/LottieImport";
import { Card } from "react-bootstrap";

function GrillPainting() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <SimpleGrid
        py={5}
        display={{ xl: "flex" }}
        justifyContent={{ xl: "center" }}
        px={{ xl: 5 }}
        alignItems={"center"}
        columns={{ base: 1, md: 1, xl: 2 }}
        gap="20px"
      >
        <VStack
        px={2}
          maxW={{ md: "28.125rem", xl: "50%" }}
          align={{ base: "start", md: "center", xl: "start" }}
          textAlign={{ base: "start", md: "center", xl: "start" }}
          spacing={{ base: "1.6rem", md: "1.65rem" }}
        >
          <HStack>
            <LottieImport
              style={{ paddingLeft: "50px", position: "absolute" }}
              json={paintJson}
            />
            <Heading color={"#00308C"} position={"relative"} as="h1" size="3xl">
              Grill Painting
            </Heading>
          </HStack>
          <Text fontSize={20} position={"relative"} lineHeight={10}>
            Grill painting refers to the process of applying paint or protective
            coatings to metal or wooden grills, such as those used for windows,
            doors, fences, or ventilation systems. Painting grills not only
            enhances their appearance but also helps to protect them from
            corrosion, weathering, and general wear and tear
          </Text>
        </VStack>
        <Box px={2} maxW={{ md: "8rem", xl: "40rem" }}>
          <Card>
            <Box p={5}>
              <Image src={img} alt="Hero image" />
            </Box>
          </Card>
        </Box>
      </SimpleGrid>
      <Box p={5}>
        <Card>
          <Box fontSize={20} p={5} lineHeight={10}>
            Surface Preparation: Before painting the grills, it is important to
            prepare the surface properly. This involves cleaning the grills to
            remove any dirt, grease, or rust. Use a wire brush or sandpaper to
            remove loose paint or rust from metal grills, and wipe down wooden
            grills to ensure a clean surface. Repair and Priming: Inspect the
            grills for any damage, such as dents or scratches. If necessary,
            repair these areas using appropriate fillers or putty. Once the
            repairs are complete, apply a primer to the grills. Priming helps
            the paint adhere better and provides a smooth and even finish. Paint
            Selection: Choose a paint that is specifically designed for metal or
            wood surfaces, depending on the material of your grills. Consider
            using paint that is formulated to withstand outdoor conditions and
            provides protection against rust, UV rays, and moisture. Painting
            Technique: When painting grills, it is important to use proper
            painting techniques to achieve a professional and even finish. Use a
            brush or spray gun to apply the paint, ensuring even coverage and
            avoiding drips or runs. Apply multiple thin coats, allowing each
            coat to dry before applying the next. Finishing and Maintenance:
            Once the paint has dried, inspect the grills for any imperfections
            or missed spots. Touch up as needed. If desired, you can apply a
            clear protective coat to enhance the durability of the paint and
            provide additional protection against the elements.
          </Box>
        </Card>
      </Box>
    </>
  );
}

export default GrillPainting;

import React from "react";
import { WhyChooseServiceWrapper, CardSectionWrapper } from "./styled";
import Dummy from "../../../assets/png/dummy.png";
import Color from "../../../assets/png/selection-color.png";
import SiteHandover from "../../../assets/png/site-handover.png";
import Walls from "../../../assets/png/signature-walls.png";
import EndService from "../../../assets/png/end-to-end.png";
import Project from "../../../assets/png/project-managment.png";
import Execution from "../../../assets/png/professional-execution.png";

const OurServices = () => {
  return (
    <div className="container">
      <WhyChooseServiceWrapper>
        <h2>Why Choose Our Services</h2>
        <div className="card-list-section">
          <CardSectionWrapper>
            <img src={Color} alt="dummy" />
            <span>Selection of colour</span>
            <p>
              Hello colors Safe Painting Service offers a curated selection of
              our favourite safe painting colours for you to choose from. Our
              expert will help you finalize the colours.
            </p>
          </CardSectionWrapper>
          <CardSectionWrapper>
            <img src={Walls} alt="dummy" />
            <span>Signature Walls</span>
            <p>
              Our Hello colors Beautiful Homes Service panel of experienced
              interior designers help you visualize and execute your vision.
              Just like you, your home interior should be the best.
            </p>
          </CardSectionWrapper>
          <CardSectionWrapper>
            <img src={EndService} alt="dummy" />
            <span>End-to-End Service</span>
            <p>
              A design service that provides seamless house; right from the home
              interior design plans to final execution. So, sit back and relax
              while Beautiful Homes experts design your dream house.
            </p>
          </CardSectionWrapper>
          <CardSectionWrapper>
            <img src={Project} alt="dummy" />
            <span>Project Managment</span>
            <p>
              As a Beautiful Home interior design service, we value your time.
              And so, right from the creation of the house design plan o project
              delivery you will get regular updates
            </p>
          </CardSectionWrapper>
          <CardSectionWrapper>
            <img src={Execution} alt="dummy" />
            <span>Professional Execution</span>
            <p>
              We use a well-established execution process with a robust project
              managment framework to ensure a high quality, on time execution of
              your home interior design!
            </p>
          </CardSectionWrapper>
          <CardSectionWrapper>
            <img src={SiteHandover} alt="dummy" />
            <span>Site handover</span>
            <p>
              Once the site is ready, we do a post-painting clean-up & home
              disinfection service and will give you a walkthrough of how to
              take care of your walls.
            </p>
          </CardSectionWrapper>
        </div>
      </WhyChooseServiceWrapper>
    </div>
  );
};

export default OurServices;

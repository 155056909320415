import styled from 'styled-components';

const GrowthSectionStyle = styled.div`
    display: flex;
    flex-direction: column;
    padding: 5rem;
    background: #e3f9e5;
    margin-bottom: 3rem;
    border-radius: 8px;
    gap: 5rem;
    @media screen and (max-width: 680px) {
        padding: 1.5rem;
        gap: 2rem;
    }

    .growth-title {
        font-style: normal;
        font-weight: 700;
        font-size: 36px;
        line-height: 140%;
        color: var(--gray-900);
        margin: 0;
        text-align: center;
        margin-bottom: -3rem !important;

        @media screen and (max-width: 680px) {
            font-size: 24px;
            text-align: center;
            margin-bottom: -2rem !important;
        }
        .heading-para {
            font-size: 20px;
            line-height: 28px;
            font-weight: 400;
            letter-spacing: 0.02em;
            color: #4d4d4d;
            width: 500px;
            margin: 0 auto;
            @media screen and (max-width: 680px) {
                width: 100%;
                font-size: 16px;
            }
        }
    }
`;

export const StepperSection = styled.div`
    display: flex;
    justify-content: space-between;
    @media screen and (max-width: 680px) {
        flex-direction: column !important;
        gap: 2rem;
        margin-bottom: 2rem;
    }
    .step-content-image {
        width: 480px;
        /* height: 400px; */
        @media screen and (max-width: 680px) {
            width: 100% !important;
            height: 100%;
        }
        &.mobile-responsive-section {
            display: none;
            @media screen and (max-width: 680px) {
                display: flex !important;
            }
        }
        &.web-responsive-section {
            display: flex;
            @media screen and (max-width: 680px) {
                display: none !important;
            }
        }
        &.box-shadow-growth {
            box-shadow: var(--box-shadow-card);
            border-radius: 10px;
        }
    }
`;

export const StepperContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 470px;
    @media screen and (max-width: 680px) {
        justify-content: center;
        align-items: center;
        width: 100% !important;
    }
    &.track-section-responsive {
        margin-top: -2rem;
    }

    p {
        font-style: normal;
        font-weight: 700;
        font-size: 30px;
        color: var(--gray-900);
        margin: 0;
        margin-top: 8px !important;
        @media screen and (max-width: 680px) {
            font-size: 24px !important;
            text-align: center;
        }
    }
    .sub-text-content {
        color: var(--gray-900);
        font-weight: 500;
        font-size: 16px;
        line-height: 140%;
        width: 400px;
        font-family: Poppins, sans-serif;
        margin-top: 14px;
        @media screen and (max-width: 680px) {
            width: 100% !important;
            text-align: center;
        }
    }
    .learn-more-button {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 12px 20px;
        max-width: 170px;
        margin-top: 60px;
        color: var(--gray-00);
        font-weight: 600;
        height: 44px;
        border: none;
        @media screen and (max-width: 680px) {
            margin-top: 2rem;
        }
    }
`;
export { GrowthSectionStyle };

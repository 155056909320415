import React, { Component } from "react";
import "./whatsapp.css";
import telePhonoe from "../../assets/png/telephone.png";
import instagram from "../../assets/png/instagram.png";
import whatapp from "../../assets/png/whatsapp.png";

export default class WhatsApp extends Component {
  render() {
    return (
      <div className="overflow-media">
        <a
          onClick={(e) =>
            window.open(
              "https://instagram.com/hellocolors.murali?igshid=NGExMmI2YTkyZg=="
            )
          }
          target="_blank"
          rel="noreferrer"
        >
          <img class="my-float" src={instagram} alt="whatapp" />
        </a>
        <a href="tel:8550000339">
          <img class="my-float" src={telePhonoe} alt="whatapp" />
        </a>
        <a
          href="https://api.whatsapp.com/send?phone=+918550000339&text=Hey%21%21%21%20%20%20Hello%20Colors."
          target="_blank"
          rel="noreferrer"
        >
          <img class="my-float" src={whatapp} alt="whatapp" />
        </a>
      </div>
    );
  }
}

import React from "react";
import { Carousel } from "rsuite";
import appartment from "../../assets/png/appartment.jpg";
import Wall from "../../assets/png/painting-walls-pink.jpg";
import Brush from "../../assets/png/brush.jpg";
import Crack from "../../assets/png/crack.jpg";
import Exterior from "../../assets/png/Exterior-wall.jpg";
import Room from "../../assets/png/interior-room.jpg";
import Interior from "../../assets/png/interior.jpg";
import Roof from "../../assets/png/roof.jpg";

import "./carousel.css";

const ImageCarousel = ({ placement, shape }) => {
  const list = [
    {
      img: Exterior,
      height: "70vh",
    },
    {
      img: Crack,
      height: "70vh",
    },
    {
      img: Brush,
      height: "70vh",
    },
    {
      img: Room,
      height: "70vh",
    },
    {
      img: Interior,
      height: "70vh",
    },
    {
      img: Roof,
      height: "70vh",
    },
  ];
  return (
    <Carousel
      placement={placement ? placement : "bottom"}
      shape={shape ? shape : "dot"}
      autoplay
      className="custom-slider"
      id="home"
    >
      {list &&
        list.length > 0 &&
        list.map((data) => (
          <img
            src={data.img}
            height={data.height ? data.height : "350px"}
            alt="carousel"
          />
        ))}
    </Carousel>
  );
};

export default ImageCarousel;

import styled from "styled-components";

export const WhyChooseServiceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
  h2 {
    color: #1f2933;
    text-align: center;
    font-size: 36px;
    font-weight: 700;
    @media screen and (max-width: 768px) {
      font-size: 24px;
    }
  }
  .card-list-section {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    gap: 2rem;
    margin-top: 2rem;
  }
`;

export const CardSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 350px;
  height: 300px;
  background-color: #e7ecef8a;
  border-radius: 8px;
  gap: 1rem;
  padding: 1.5rem;
  @media screen and (max-width: 768px) {
    width: 100%;
    height: 300px;
  }
  img {
    width: 70px;
    height: 70px;
  }
  span {
    font-size: 18px;
    font-weight: 900;
    color: #1f2933;
  }
  p {
    font-size: 14px;
    font-weight: 400;
    color: #323f4b;

    text-align: center;
    margin: 0;
  }
`;

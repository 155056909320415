import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "rsuite/dist/rsuite.min.css";
import "../src/scss/style.scss";
import "./App.css";
import DefaultLayout from "./components/layout/DefaultLayout";
import Home from "./pages/Home";
import Gallery from "./pages/components/Gallery/Gallery";
import HousePaining from "./pages/components/services/HousePainting";
import { ChakraProvider } from "@chakra-ui/react";
import InteriorPainting from "./pages/components/services/InteriorPainting";
import WallCare from "./pages/components/services/WallCare";
import Sticker from "./pages/components/services/Sticker";
import GrillPainting from "./pages/components/services/GrillPainting";
import CrackTreatment from "./pages/components/services/CrackTreatment";
import WaterLeaking from "./pages/components/services/WaterLeaking";
import WindowDesign from "./pages/components/services/WindowDesign";

function App() {
  return (
    <ChakraProvider>
      <BrowserRouter>
        <Routes>
          {/* Home */}
          <Route path="/" element={<DefaultLayout exact component={Home} />} />
          <Route
            path="/gallery"
            element={<DefaultLayout component={Gallery} />}
          />
          <Route
            path="/service/house-painting"
            element={<DefaultLayout component={HousePaining} />}
          />
          <Route
            path="/service/interior-painting"
            element={<DefaultLayout component={InteriorPainting} />}
          />
          <Route
            path="/service/wallcare"
            element={<DefaultLayout component={WallCare} />}
          />
          <Route
            path="/service/stickering"
            element={<DefaultLayout component={Sticker} />}
          />
          <Route
            path="/service/grill-painting"
            element={<DefaultLayout component={GrillPainting} />}
          />
          <Route
            path="/service/crack-treatment"
            element={<DefaultLayout component={CrackTreatment} />}
          />
          <Route
            path="/service/water-leaking-treatment"
            element={<DefaultLayout component={WaterLeaking} />}
          />
          <Route
            path="/service/door-window-painting"
            element={<DefaultLayout component={WindowDesign} />}
          />
        </Routes>
      </BrowserRouter>
    </ChakraProvider>
  );
}

export default App;

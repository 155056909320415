import React, { useEffect } from "react";
import { v4 as uuid } from "uuid";
import GalleryShow from "../../../components/GalleryShow/GalleryShow";

import Img1 from "../../../assets/png/Crack_Free_walls.jpg";
import Img2 from "../../../assets/png/appartment.jpg";
import Img3 from "../../../assets/png/blue-wall.jpg";
import Img4 from "../../../assets/png/brush.jpg";
import Img5 from "../../../assets/png/buckets-paint-floor.jpg";
import Img6 from "../../../assets/png/bule2.png";
import Img7 from "../../../assets/png/closeup-house-painting-renovation.jpg";
import Img8 from "../../../assets/png/Crack_Free_walls.jpg";
import Img9 from "../../../assets/png/crack.jpg";
import Img10 from "../../../assets/png/Dampproof.jpg";
import Img11 from "../../../assets/png/Exterior-wall.jpg";
import Img12 from "../../../assets/png/hairline-crack-wall.jpg";
import Img13 from "../../../assets/png/house.jpg";
import Img14 from "../../../assets/png/image.jpg";
import Img15 from "../../../assets/png/Instawalls.jpg";
import Img16 from "../../../assets/png/interior-room.jpg";
import Img17 from "../../../assets/png/interior.jpg";
import Img18 from "../../../assets/png/painting-walls-pink.jpg";
import Img19 from "../../../assets/png/pink.jpg";
import Img20 from "../../../assets/png/roof.jpg";

import Img21 from "../../../assets/png/Terrace_waterproofing.jpg";
import Img22 from "../../../assets/png/Ultimate_protection.jpg";
import Img23 from "../../../assets/png/wall-exterior-paint.png";
import Img24 from "../../../assets/png/wall1.jpeg";
import Img25 from "../../../assets/png/wall2.jpeg";
import Img26 from "../../../assets/png/yellow-paint.jpg";
import Img27 from "../../../assets/png/closeup-house-painting-renovation.jpg";
import Img28 from "../../../assets/png/Cracking.jpg";
import Img29 from "../../../assets/png/download.jpeg";
import Img30 from "../../../assets/png/download1.jpeg";
import Img31 from "../../../assets/png/download2.jpeg";
import Img32 from "../../../assets/png/download3.jpg";

const image = [
  {
    key: uuid(),
    content: <img src={Img1} alt="1" />,
  },
  {
    key: uuid(),
    content: <img src={Img2} alt="2" />,
  },
  {
    key: uuid(),
    content: <img src={Img3} alt="3" />,
  },
  {
    key: uuid(),
    content: <img src={Img4} alt="3" />,
  },
  {
    key: uuid(),
    content: <img src={Img5} alt="5" />,
  },
  {
    key: uuid(),
    content: <img src={Img6} alt="6" />,
  },
  {
    key: uuid(),
    content: <img src={Img7} alt="7" />,
  },
  {
    key: uuid(),
    content: <img src={Img8} alt="8" />,
  },
  {
    key: uuid(),
    content: <img src={Img9} alt="9" />,
  },
  {
    key: uuid(),
    content: <img src={Img10} alt="10" />,
  },
  {
    key: uuid(),
    content: <img src={Img11} alt="11" />,
  },
  {
    key: uuid(),
    content: <img src={Img12} alt="12" />,
  },
  {
    key: uuid(),
    content: <img src={Img13} alt="12" />,
  },
  {
    key: uuid(),
    content: <img src={Img14} alt="12" />,
  },
  {
    key: uuid(),
    content: <img src={Img15} alt="12" />,
  },
  {
    key: uuid(),
    content: <img src={Img16} alt="12" />,
  },
  {
    key: uuid(),
    content: <img src={Img17} alt="12" />,
  },
  {
    key: uuid(),
    content: <img src={Img18} alt="12" />,
  },
  {
    key: uuid(),
    content: <img src={Img19} alt="12" />,
  },
  {
    key: uuid(),
    content: <img src={Img20} alt="12" />,
  },
  {
    key: uuid(),
    content: <img src={Img21} alt="12" />,
  },
  {
    key: uuid(),
    content: <img src={Img22} alt="12" />,
  },
  {
    key: uuid(),
    content: <img src={Img23} alt="12" />,
  },
  {
    key: uuid(),
    content: <img src={Img24} alt="12" />,
  },
  {
    key: uuid(),
    content: <img src={Img25} alt="12" />,
  },
  {
    key: uuid(),
    content: <img src={Img26} alt="12" />,
  },
  {
    key: uuid(),
    content: <img src={Img27} alt="12" />,
  },
  {
    key: uuid(),
    content: <img src={Img28} alt="12" />,
  },
  {
    key: uuid(),
    content: <img src={Img29} alt="12" />,
  },
  {
    key: uuid(),
    content: <img src={Img30} alt="12" />,
  },
  {
    key: uuid(),
    content: <img src={Img31} alt="12" />,
  },
  {
    key: uuid(),
    content: <img src={Img32} alt="12" />,
  },
];
const Gallery = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="pt-4" style={{ paddingBottom: "6rem" }}>
      <h2
        className="font-weight-bold pt-3 pb-3 text-center"
        style={{
          fontSize: "2rem",
          fontWeight: "bold",
        }}
      >
        Gallery
      </h2>
      <GalleryShow images={image} />
    </div>
  );
};

export default Gallery;

import React from "react";
import Carousel from "../components/carousel/ImageCarousel";
import ProductStep from "./components/ProductStep";
import About from "./components/About";
import GalleryView from "./components/GallerySection/index";
import OurServices from "./components/whyChooseService/index";

const Home = () => {
  return (
    <>
      <Carousel />
      <About />
      <ProductStep />
      <GalleryView />
      <OurServices />
    </>
  );
};

export default Home;

import styled from "styled-components";

export const GallerySection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3rem;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;

  h2 {
    font-size: 36px;
    color: #000000;
    font-weight: 700;
    @media screen and (max-width: 768px) {
      font-size: 24px;
    }
  }

  .image-section {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    padding: 1rem;
    @media screen and (max-width: 768px) {
      flex-direction: column;
    }

    img {
      width: 400px;
      height: 400px;
      border-radius: 8px;
      object-fit: cover;
      @media screen and (max-width: 768px) {
        width: 100%;
        height: 400px;
      }
    }
  }
  .view-more-gallery {
    background-color: #1f2933;
    border: none;
    font-size: 18px;
    font-weight: 600;
    &:hover {
      background-color: #1f2933;
    }
  }
`;

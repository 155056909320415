import {
  Box,
  HStack,
  Heading,
  Image,
  SimpleGrid,
  Text,
  VStack,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import paintJson from "../../../assets/json/dots.json";
import img from "../../../assets/service/sticker.jpg";
import img1 from "../../../assets/service/design.jpg";
import LottieImport from "../base/LottieImport";
import { Card } from "react-bootstrap";

function Sticker() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <SimpleGrid
        py={5}
        display={{ xl: "flex" }}
        justifyContent={{ xl: "center" }}
        px={{ xl: 5 }}
        alignItems={"center"}
        columns={{ base: 1, md: 1, xl: 2 }}
        gap="20px"
      >
        <VStack
         px={3}
          maxW={{ md: "28.125rem", xl: "100%" }}
          align={{ base: "start", md: "center", xl: "start" }}
          textAlign={{ base: "start", md: "center", xl: "start" }}
          spacing={{ base: "1.6rem", md: "1.65rem" }}
        >
          <HStack>
            <LottieImport style={{ position: "absolute" }} json={paintJson} />
            <Heading color={'#00308C'} position={"relative"} as="h1" size="3xl">
              Wall Sticker
            </Heading>
          </HStack>
          <Text fontSize={20} position={"relative"} color="muted">
            A wall sticker service is a professional service that specializes in
            the design, production, and installation of wall decals or wall
            stickers. Wall stickers are adhesive graphics or designs that can be
            applied to walls, creating a decorative and visually appealing
            element in a space.
          </Text>
        </VStack>
        <Box maxW={{ md: "8rem", xl: "40rem" }}>
          <Card>
            <Box p={5}>
              <Image src={img} alt="Hero image" />
            </Box>
          </Card>
        </Box>
      </SimpleGrid>
      <SimpleGrid
        py={5}
        display={{ xl: "flex" }}
        justifyContent={{ xl: "center" }}
        px={{ xl: 5 }}
        alignItems={"center"}
        columns={{ base: 1, md: 1, xl: 2 }}
        gap="20px"
      >
        <Box maxW={{ md: "8rem", xl: "40rem" }}>
          <Card>
            <Box p={5}>
              <Image src={img1} alt="Hero image" />
            </Box>
          </Card>
        </Box>
        {/* <LottieImport style={{ position: "absolute" }} json={paintJson} /> */}
        <VStack
       
          maxW={{ md: "28.125rem", xl: "100%" }}
          align={{ base: "start", md: "center", xl: "start" }}
          textAlign={{ base: "start", md: "center", xl: "start" }}
          spacing={{ base: "1.6rem", md: "1.65rem" }}
        >
          <HStack>
            <Heading  px={3} color={'#00308C'} position={"relative"} as="h1" size="3xl">
              Wall Paper Design
            </Heading>
          </HStack>

          <Text  px={3} fontSize={20} position={"relative"}>
            Wallpaper design offers a wide range of possibilities to transform
            the look and feel of your space. From traditional patterns to modern
            and abstract designs, wallpaper can add depth, texture, and visual
            interest to your walls.
            <Text pt={2} fontSize={20} position={"relative"}>
              Floral wallpapers bring a touch of nature indoors and create a
              serene and refreshing atmosphere. They come in a variety of
              styles, from delicate and intricate patterns to bold and oversized
              blooms, allowing you to choose the level of impact you desire.
            </Text>
          </Text>
        </VStack>
      </SimpleGrid>
    </>
  );
}

export default Sticker;

export function sidebarList() {
  // Sidebar list options
  let sidebarOptions = [];
  sidebarOptions.push(
    { key: "home", label: "Home", link: "/" },
    { key: "about", label: "About", link: "/" },
    { key: "gallery", label: "Gallery", link: "/" }
  );

  sidebarOptions.push({
    key: "service",
    label: "Service",
    options: [
      {
        key: "house-painting",
        label: "New house painting & design decorations",
        link: "/service/house-painting",
      },
      {
        key: "interior-exterior-painting",
        label: "Interior & exterior painting",
        link: "/service/interior-painting",
      },
      {
        key: "wall-care",
        label: "Wall care",
        link: "/service/wallcare",
      },
      {
        key: "sticker",
        label: "Wall sticker & wall paper design",
        link: "/service/stickering",
      },
      {
        key: "grill-painting",
        label: "Grill painting",
        link: "/service/grill-painting",
      },
      {
        key: "crack-treatment",
        label: "Crack treatment",
        link: "/service/crack-treatment",
      },
      {
        key: "water-leaking-treatment",
        label: "Water leaking treatment",
        link: "/service/water-leaking-treatment",
      },
      {
        key: "door-window-design-painting",
        label: "Door & window design and painting",
        link: "/service/door-window-painting",
      },
    ],
  });
  return sidebarOptions;
}

import classNames from "classnames";
import { array, bool, string } from "prop-types";
import React, { useEffect, useState } from "react";
import { sidebarList } from "../../SidebarConfig";

// Custom Sidebar components
import SidebarList from "./SidebarList";
import SidebarListItem, {
  SidebarBackListItem,
  SidebarListItemWithOptions,
} from "./SidebarListItem";

import css from "./Sidebar.module.scss";

const CLOSE_SUB_MENU_DELAY = 300;

const Sidebar = (props) => {
  const { rootClassName, className, isMenuOpen, setMenuOpen, sidebarOptions } =
    props;
  const sidebarClasses = classNames(rootClassName || css.root, className, {
    [css.sidebarOpen]: isMenuOpen,
  });

  // Handle sub items state
  const [activeSubItem, setActiveSubItem] = useState(null);

  useEffect(() => {
    const closeSubMenu = !isMenuOpen && activeSubItem;
    if (closeSubMenu) {
      setTimeout(() => {
        setActiveSubItem(null);
      }, [CLOSE_SUB_MENU_DELAY]);
    }
  }, [isMenuOpen, activeSubItem]);

  // Find the subItem options which will get
  // filled once the user selects the item that
  // has additional options (sub items)
  const subItemOptions = sidebarOptions.find(
    (s) => s.key === activeSubItem
  )?.options;

  // Render sidebar options from
  // config.js file
  const options = subItemOptions ? subItemOptions : sidebarOptions;
  const renderSidebarOptions = options.map((option) => {
    return option.options ? (
      <SidebarListItemWithOptions
        key={option.key}
        item={option}
        activeSubItem={activeSubItem}
        setActiveSubItem={setActiveSubItem}
      />
    ) : (
      <SidebarListItem
        setMenuOpen={setMenuOpen}
        key={option.key}
        item={option}
      />
    );
  });

  return (
    <div className={sidebarClasses}>
      <SidebarList>
        {subItemOptions ? (
          <SidebarBackListItem setActiveSubItem={setActiveSubItem} />
        ) : null}
        {renderSidebarOptions}
      </SidebarList>
    </div>
  );
};

Sidebar.defaultProps = {
  rootClassName: null,
  className: null,
  isMenuOpen: false,

  // Options
  sidebarOptions: sidebarList(),
};

Sidebar.propTypes = {
  rootClassName: string,
  className: string,
  isMenuOpen: bool.isRequired,

  // Options
  sidebarOptions: array.isRequired,
};

export default Sidebar;

import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";

// Custom Header components
import HeaderNavigation from "./Navigation";

import Sidebar from "../sidebar/Sidebar";
import css from "./Header.module.scss";

const Header = (props) => {
  const { rootClassName, className, isMenuOpen, setMenuOpen } = props;

  const classes = classNames(rootClassName || css.root, className);
  return (
    <div className={classes}>
      <div className={css.headerContent}>
        <HeaderNavigation isMenuOpen={isMenuOpen} setMenuOpen={setMenuOpen} />
      </div>
      <Sidebar isMenuOpen={isMenuOpen} setMenuOpen={setMenuOpen} />
    </div>
  );
};

const { string, bool, func } = PropTypes;

Header.defaultProps = {
  rootClassName: null,
  className: null,
  isMenuOpen: false,
  setMenuOpen: null,
};

Header.propTypes = {
  rootClassName: string,
  className: string,
  isMenuOpen: bool.isRequired,
  setMenuOpen: func.isRequired,
};

export default Header;

import React from "react";
import { GrowthSectionStyle, StepperSection, StepperContent } from "./styled";
import Walls from "../../../assets/png/Instawalls.jpg";
import WallProduction from "../../../assets/png/Ultimate_protection.jpg";
import HairCracking from "../../../assets/png/hairline-crack-wall.jpg";
import HousePainting from "../../../assets/png/house.jpg";

import { Button } from "react-bootstrap";

import { useNavigate } from "react-router-dom";

const GrowthSection = () => {
  const navigate = useNavigate();
  return (
    <div className="container">
      <GrowthSectionStyle>
        <span className="growth-title">
          Your partner in home transformation
          <p className="heading-para">
            unique transformations for your space are just a click away. Whether
            you want to revamp an entire home or focus on one wall, our
            solutions allow you to easily customize your experience and create
            something unique.
          </p>
        </span>

        <StepperSection>
          <img src={Walls} alt="how-to-growth" className="step-content-image" />
          <StepperContent>
            <p>instawalls</p>
            <span className="sub-text-content">
              Banish boring walls and make it more interesting with mini
              makeovers. Just in a day!
            </span>
          </StepperContent>
        </StepperSection>

        <StepperSection className="track-section-responsive">
          <img
            src={WallProduction}
            alt="how-to-growth"
            className="step-content-image mobile-responsive-section box-shadow-growth"
          />
          <StepperContent className="track-section-responsive">
            <p>protect your walls with pros</p>
            <span className="sub-text-content">
              let’s make your home attractive! why settle for white walls when
              you have an assortment of colours ranging from simple nude tones
              to exuberant yellows and pinks in the palm of your hand
            </span>
          </StepperContent>

          <img
            src={WallProduction}
            alt="how-to-growth"
            className="step-content-image web-responsive-section box-shadow-growth"
          />
        </StepperSection>

        <StepperSection>
          <img
            src={HairCracking}
            alt="how-to-growth"
            className="step-content-image box-shadow-growth"
          />
          <StepperContent>
            <p>Interior Paint Cracking, Flaking or Peeling</p>
            <span className="sub-text-content">
              Remove all of the cracked and/or flaked paint in the damaged
              areas, using paint-sanders*, scrapers, or heat guns.Use the
              appropriate primer to pre-coat and seal the prepped surfaces.
            </span>
          </StepperContent>
        </StepperSection>

        <StepperSection>
          <img
            src={HousePainting}
            alt="how-to-growth"
            className="step-content-image mobile-responsive-section"
          />
          <StepperContent>
            <p>Exterior Painting and Interior Painting</p>
            <span className="sub-text-content">
              Make your home weatherproof and exterior walls durable with
              professional exterior painting.Interior painting service includes
              Fresh painting and Re-painting with Stencil, Texture, and
              Designerwalls.
            </span>
          </StepperContent>
          <img
            src={HousePainting}
            alt="how-to-growth"
            className="step-content-image web-responsive-section"
          />
        </StepperSection>
      </GrowthSectionStyle>
    </div>
  );
};

export default GrowthSection;

import {
  Box,
  HStack,
  Heading,
  Image,
  SimpleGrid,
  Text,
  VStack,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import paintJson from "../../../assets/json/particle.json";
import img from "../../../assets/service/crack.jpg";
import LottieImport from "../base/LottieImport";
import { Card } from "react-bootstrap";

function CrackTreatment() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <SimpleGrid
        py={5}
        display={{ xl: "flex" }}
        justifyContent={{ xl: "center" }}
        px={{ xl: 5 }}
        alignItems={"center"}
        columns={{ base: 1, md: 1, xl: 2 }}
        gap="20px"
      >
        <VStack
          px={2}
          maxW={{ md: "28.125rem", xl: "50%" }}
          align={{ base: "start", md: "center", xl: "start" }}
          textAlign={{ base: "start", md: "center", xl: "start" }}
          spacing={{ base: "1.6rem", md: "1.65rem" }}
        >
          <HStack>
            <LottieImport
              style={{
                paddingLeft: "50px",
                height: "100px",
                position: "absolute",
              }}
              json={paintJson}
            />
            <Heading color={"#00308C"} position={"relative"} as="h1" size="3xl">
              Crack Treatment
            </Heading>
          </HStack>
          <Text fontSize={20} position={"relative"} lineHeight={10}>
            Crack treatment refers to the process of repairing and addressing
            cracks that may develop in walls, ceilings, or other surfaces.
            Cracks can occur due to various factors such as settling of the
            building, temperature fluctuations, moisture, or structural issues.
            Treating cracks helps to prevent further damage, maintain the
            integrity of the surface, and improve the overall appearance.
          </Text>
        </VStack>
        <Box px={2} maxW={{ md: "8rem", xl: "40rem" }}>
          <Card>
            <Box p={5}>
              <Image src={img} alt="Hero image" />
            </Box>
          </Card>
        </Box>
      </SimpleGrid>
      <Box p={5}>
        <Card>
          <Box fontSize={20} p={5} lineHeight={10}>
            Assessment: Begin by examining the cracks to determine their
            severity and potential causes. Identify whether the cracks are
            superficial or indicative of underlying structural issues. If you're
            unsure about the severity of the cracks or their cause, it is
            advisable to consult a professional, such as a contractor or
            structural engineer, for an accurate assessment. Surface
            Preparation: Before treating the cracks, it is important to prepare
            the surface. Clean the area around the cracks by removing any loose
            debris, dust, or old paint. Use a scraper, sandpaper, or a wire
            brush to create a clean and smooth surface for the repair. Crack
            Repair: The repair method depends on the type and size of the
            cracks. For small, hairline cracks, you can use a filler or patching
            compound. Apply the filler or compound using a putty knife, ensuring
            it fills the crack completely. Smooth out the surface and let it dry
            as per the manufacturer's instructions. For larger cracks or those
            indicating structural issues, it is crucial to consult a
            professional to determine the appropriate repair method. Sanding and
            Priming: Once the crack repair material has dried, sand the repaired
            area to create a smooth surface. Use sandpaper or a sanding block to
            feather the edges of the repair, blending it with the surrounding
            surface. After sanding, apply a primer to the repaired area. Priming
            helps the paint adhere better and provides an even finish. Paint and
            Finishing: After the primer has dried, paint the treated area to
            match the surrounding surface. Use the appropriate paint type and
            color, ensuring it blends seamlessly with the existing paint. Apply
            multiple thin coats of paint, allowing each coat to dry before
            applying the next. Once the paint has dried, inspect the treated
            area for any imperfections and touch up as necessary.
          </Box>
        </Card>
      </Box>
    </>
  );
}

export default CrackTreatment;

import {
  Box,
  HStack,
  Heading,
  Image,
  SimpleGrid,
  Text,
  VStack,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import paintJson from "../../../assets/json/drops.json";
import img from "../../../assets/service/wallCare.jpg";
import img1 from "../../../assets/png/closeup-house-painting-renovation.jpg";
import LottieImport from "../base/LottieImport";
import { Card } from "react-bootstrap";

function WallCare() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <SimpleGrid
        py={5}
        display={{ xl: "flex" }}
        justifyContent={{ xl: "center" }}
        px={{ xl: 5 }}
        alignItems={"center"}
        columns={{ base: 1, md: 1, xl: 2 }}
        gap="20px"
      >
        <VStack
          maxW={{ md: "28.125rem", xl: "50%" }}
          align={{ base: "start", md: "center", xl: "start" }}
          textAlign={{ base: "start", md: "center", xl: "start" }}
          spacing={{ base: "1.6rem", md: "1.65rem" }}
        >
          <HStack>
            <Heading color={"#00308C"} position={"relative"} as="h1" size="3xl">
              Wall Care
            </Heading>
            <LottieImport
              style={{ paddingLeft: "200px", position: "absolute" }}
              json={paintJson}
            />
          </HStack>

          <Text px={3} fontSize={20} position={"relative"} lineHeight={10}>
            Wall care servicing is a professional service that ensures the
            proper maintenance and care of your walls. It involves a
            comprehensive approach to inspect, clean, repair, and protect your
            walls to maintain their appearance and structural integrity. Here
            are some common services offered as part of wall care servicing:
          </Text>
        </VStack>
        <Box maxW={{ md: "8rem", xl: "40rem" }}>
          <Card>
            <Box p={5}>
              <Image src={img} alt="Hero image" />
            </Box>
          </Card>
        </Box>
      </SimpleGrid>
      <Box p={5}>
        <Card>
          <Box fontSize={20} p={5} lineHeight={10}>
            Inspection: A professional wall care servicing begins with a
            thorough inspection of your walls. The service provider will assess
            the condition of the walls, look for any visible damage, cracks, or
            signs of moisture intrusion, and identify areas that require
            attention. Cleaning: Professional wall care servicing includes a
            deep cleaning of your walls to remove dirt, dust, stains, and grime.
            They will use appropriate cleaning solutions and techniques that are
            safe for your specific wall materials, such as painted walls,
            wallpaper, or textured finishes. Repair: If any damage or
            imperfections are found during the inspection, the wall care service
            provider will address them. This may involve patching holes,
            repairing cracks, fixing loose wallpaper, or addressing other issues
            to restore the wall's integrity and appearance. Surface Preparation:
            Before any wall treatments or finishes are applied, proper surface
            preparation is crucial. The wall care service provider will ensure
            that the walls are clean, smooth, and free from any debris, loose
            paint, or imperfections. This step ensures better adhesion and a
            professional finish. Painting or Wall Treatments: If desired, the
            wall care service provider can offer painting services or apply
            other wall treatments, such as wallpaper installation, textured
            finishes, or decorative wall coatings. They will help you choose the
            right colors or designs to enhance the aesthetics of your space.
            Protective Coatings: To enhance the durability and longevity of your
            walls, the wall care servicing may include the application of
            protective coatings. These coatings can provide added resistance
            against moisture, stains, fading, and damage from daily wear and
            tear. Maintenance Recommendations: After completing the wall care
            servicing, the professionals may provide you with maintenance
            recommendations to help you keep your walls in good condition. This
            may include tips for regular cleaning, preventive measures to
            minimize damage, and suggestions for touch-up or maintenance
            painting as needed.
          </Box>
        </Card>
      </Box>
    </>
  );
}

export default WallCare;

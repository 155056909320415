import React from "react";
import Lottie from "react-lottie";

const LottieImport = (props) => {
  const { json, style } = props;
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: json,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <div style={style}>
      <Lottie options={defaultOptions} />
    </div>
  );
};

export default LottieImport;

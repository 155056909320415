import {
  Box,
  HStack,
  Heading,
  Image,
  SimpleGrid,
  Text,
  VStack,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import paintJson from "../../../assets/json/bubble.json";
import img from "../../../assets/service/doorpaint.jpg";
import img1 from "../../../assets/service/design.jpg";
import LottieImport from "../base/LottieImport";
import { Card } from "react-bootstrap";

function WindowDesign() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <SimpleGrid
        py={5}
        display={{ xl: "flex" }}
        justifyContent={{ xl: "center" }}
        px={{ xl: 5, sm: 2 }}
        alignItems={"center"}
        columns={{ base: 1, md: 1, xl: 2 }}
        gap="20px"
      >
        <VStack
          px={2}
          maxW={{ md: "28.125rem", xl: "100%" }}
          align={{ base: "start", md: "center", xl: "start" }}
          textAlign={{ base: "start", md: "center", xl: "start" }}
          spacing={{ base: "1.6rem", md: "1.65rem" }}
        >
          <HStack>
            <LottieImport style={{ position: "absolute" }} json={paintJson} />
            <Heading color={"#00308C"} position={"relative"} as="h1" size="3xl">
              Door Painting
            </Heading>
          </HStack>
          <Text fontSize={20} position={"relative"} color="muted">
            Door painting refers to the process of applying decorative paint
            finishes to doors to enhance their aesthetic appeal and complement
            the overall style of a space. It allows you to personalize your
            doors and make them stand out as focal points.
          </Text>
        </VStack>
        <Box px={2} maxW={{ md: "8rem", xl: "40rem" }}>
          <Card>
            <Box p={5}>
              <Image src={img} alt="Hero image" />
            </Box>
          </Card>
        </Box>
      </SimpleGrid>
      <SimpleGrid
        py={5}
        display={{ xl: "flex" }}
        justifyContent={{ xl: "center" }}
        px={{ xl: 5 }}
        alignItems={"center"}
        columns={{ base: 1, md: 1, xl: 2 }}
        gap="20px"
      >
        <Box px={2} maxW={{ md: "8rem", xl: "40rem" }}>
          <Card>
            <Box p={5}>
              <Image src={img1} alt="Hero image" />
            </Box>
          </Card>
        </Box>
        {/* <LottieImport style={{ position: "absolute" }} json={paintJson} /> */}
        <VStack
          px={2}
          maxW={{ md: "28.125rem", xl: "100%" }}
          align={{ base: "start", md: "center", xl: "start" }}
          textAlign={{ base: "start", md: "center", xl: "start" }}
          spacing={{ base: "1.6rem", md: "1.65rem" }}
        >
          <HStack pt={3}>
            <Heading color={"#00308C"} position={"relative"} as="h1" size="3xl">
              Window Painting
            </Heading>
          </HStack>

          <Text fontSize={20} position={"relative"}>
            Window painting refers to the process of applying decorative paint
            finishes to windows, adding visual interest and enhancing the
            overall aesthetic appeal of the windows. It allows you to customize
            and transform the look of your windows to suit your style and
            preferences.
            <Text pt={2} fontSize={20} position={"relative"}>
              Design Concept: Start by determining the design concept you want
              to achieve for your windows. Consider the architectural style of
              your home, the overall decor theme, and the desired level of
              intricacy. You can opt for simple geometric patterns,
              nature-inspired motifs, intricate details, or even custom designs
              that reflect your personal taste.
            </Text>
          </Text>
        </VStack>
      </SimpleGrid>
    </>
  );
}

export default WindowDesign;

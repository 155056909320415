import {
  Box,
  HStack,
  Heading,
  Image,
  Show,
  SimpleGrid,
  Text,
  VStack,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import paintJson from "../../../assets/json/waves.json";
import img from "../../../assets/service/inte-ext.jpg";
import img1 from "../../../assets/service/exterierpaint.jpg";
import LottieImport from "../base/LottieImport";
import { Card } from "react-bootstrap";

function InteriorPainting() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <SimpleGrid
        py={5}
        display={{ xl: "flex" }}
        justifyContent={{ xl: "center" }}
        px={{ xl: 5 }}
        alignItems={"center"}
        columns={{ base: 1, md: 1, xl: 2 }}
        gap="20px"
      >
        <VStack
          px={2}
          maxW={{ md: "28.125rem", xl: "100%" }}
          align={{ base: "start", md: "center", xl: "start" }}
          textAlign={{ base: "start", md: "center", xl: "start" }}
          spacing={{ base: "1.6rem", md: "1.65rem" }}
        >
          <HStack>
            <LottieImport style={{ position: "absolute" }} json={paintJson} />
            <Heading color={"#00308C"} position={"relative"} as="h1" size="3xl">
              Interior Painting
            </Heading>
          </HStack>
          <Text fontSize={20} position={"relative"} color="muted">
            Interior wall painting is a transformative process that can breathe
            new life into your living spaces. With the right choice of paint
            color and finish, you can create the desired ambiance and style for
            each room. Whether you're aiming for a cozy and inviting atmosphere
            or a vibrant and energizing space, interior wall painting is the key
            to achieving your vision.
          </Text>
        </VStack>
        <Box px={2} maxW={{ md: "8rem", xl: "40rem" }}>
          <Card>
            <Box p={5}>
              <Image src={img} alt="Hero image" />
            </Box>
          </Card>
        </Box>
      </SimpleGrid>
      <SimpleGrid
        py={5}
        display={{ xl: "flex" }}
        justifyContent={{ xl: "center" }}
        px={{ xl: 5 }}
        alignItems={"center"}
        columns={{ base: 1, md: 1, xl: 2 }}
        gap="20px"
      >
        <Box px={2} maxW={{ md: "8rem", xl: "40rem" }}>
          <Card>
            <Box p={5}>
              <Image src={img1} alt="Hero image" />
            </Box>
          </Card>
        </Box>
        <VStack
          px={2}
          pt={3}
          maxW={{ md: "28.125rem", xl: "100%" }}
          align={{ base: "start", md: "center", xl: "start" }}
          textAlign={{ base: "start", md: "center", xl: "start" }}
          spacing={{ base: "1.6rem", md: "1.65rem" }}
        >
          <Show below="xl">
            <LottieImport
              style={{
                paddingLeft: "50px",
                position: "absolute",
              }}
              json={paintJson}
            />
          </Show>
          <HStack>
            <Heading color={"#00308C"} position={"relative"} as="h1" size="3xl">
              Exterior Painting
            </Heading>
          </HStack>

          <Text fontSize={20} position={"relative"}>
            Exterior wall painting is a significant undertaking that can
            dramatically enhance the curb appeal and protect the exterior
            surfaces of your home. Choosing the right exterior wall paint is
            essential to ensure longevity, durability, and a visually appealing
            result.
            <Text pt={2} fontSize={20} position={"relative"}>
              When selecting paint for your exterior walls, it's important to
              consider factors such as climate, architectural style, and
              personal preference. Exterior paints should be able to withstand
              harsh weather conditions, resist fading, and provide protection
              against moisture and UV rays.
            </Text>
          </Text>
        </VStack>
      </SimpleGrid>
    </>
  );
}

export default InteriorPainting;
